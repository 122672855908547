import {mapActions, mapMutations, mapGetters} from 'vuex';

import {required, email, maxLength} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";

export default {
  name: 'login',
  mixins: [validationMixin],
  data() {
    return {
      payload: {
        email: '',
        password: ''
      },
      isLoading: false,
      isPasswordShow: false,
      validationErrors: {},
    }
  },
  components: {},
  validations: {
    payload: {
      password: {
        required,
        maxLength: maxLength(20),
      },
      email: {
        required,
        email,
        maxLength: maxLength(40),
      },
    }
  },
  computed: {
    isDisabled() {
      return this.payload.password === '' ||
        this.payload.email === '';
    },
    passwordErrors() {
      let error = [];
      if (!this.$v.payload.password.$dirty) {
        return error;
      }
      if (!this.$v.payload.password.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.password.maxLength) {
        error.push(this.$t('validationMax',{count: 20}));
      }
      if (this.validationErrors.password) {
        this.validationErrors.password.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    emailErrors() {
      let error = [];
      if (!this.$v.payload.email.$dirty) {
        return error;
      }
      if (!this.$v.payload.email.maxLength) {
        error.push(this.$t('validationMax',{count: 40}));
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('validationEmail'))
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },

  },
  methods: {
    ...mapActions({
      login: 'auth/AUTH_REQUEST_LOGIN',
      fetchUser: 'profile/PROFILE_REQUEST_USER_DATA_GET',
    }),
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isLoading = true;
        this.login(this.payload)
          .then(() => {
            this.$toasted.success(this.$t('successAuth'));
            this.fetchUser().then(() => {
              this.$router.push({name: 'dashboard'}).catch(() => {console.log();});
            }).finally(()=>{
              this.isLoading = false;
            })
          }).catch(error => {
          if (error.response.status === 418) {
            this.$toasted.error(error.response.data.message);
          }
          this.isLoading = false;
        });
      }
    },
  }
}
